import EvectionList from "@/page/admin/myOrder/component/evectionList";
import WarningBox from '@/page/admin/myOrder/component/warningBox'

import financeEvectionList from '@/lib/data-service/haolv-default/consumer_evection_financeEvectionList'
export default {
    data() {
        return {
            formData: {
                currentPage: 1,
                pageSize: 10,
                totalPage: 0,
                startDateTime: '',
                endDateTime: '',
                evectionStatus: 2,
                selectType: 1
            },
            evecList: [],
            formDate: '',
        }
    },
    methods: {
        change_date (val) {
            const form = this.formData;
            if (val) {
                form.startDateTime = val[0];
                form.endDateTime = val[1];
            } else {
                form.startDateTime = '';
                form.endDateTime = '';
            }
        },
        search() {
            let data = this.formData
            financeEvectionList(data).then(res => {
                console.log(res)
                this.evecList = res.datas.list
                this.formData.totalPage = res.datas.totalCount
            })
        },
        handleCurrentChange(val) {
            this.formData.currentPage = val
            this.search()
        }
    },
    created() {
        this.search()
    },
    components: {
        EvectionList,
        WarningBox
    }
}